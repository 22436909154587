const dialogues = {
  1: 'One ring to rule them all',
  2: 'Doctor who',
  3: 'Number of times Sheldon knocks',
  4: 'May the Fourth be with you',
  5: 'The magical prime',
  6: 'Number of infinity stones',
  7: 'Bond. James Bond.',
  8: 'Ball pool',
  9: 'Cats lives',
  10: 'Board exams class',
  11: 'Easiest Mathematical table',
  12: 'Monkeys',
  13: 'Unlucky number',
  14: 'Valentine Day',
  15: 'Independence Day',
  16: 'Sweet Sixteen',
  17: 'Lucky 17',
  18: 'The age of adulthood',
  19: 'End of the teens',
  20: 'Blind 20',
  21: 'Official marriage age',
  22: 'Two little ducks',
  23: 'Number of chromosomes',
  24: 'Two dozen',
  25: 'Silver Jubilee Number',
  26: 'The republic day',
  27: 'Gateway to heaven',
  28: 'The Angel Number',
  29: 'In your prime',
  30: 'Its middle Age',
  31: 'Time for fun',
  32: 'Mouth Full',
  33: 'All the 3s',
  34: 'The unspoken rule',
  35: 'Flirty Husband',
  36: 'Popular size',
  37: 'Mixed luck',
  38: 'Oversize',
  39: 'Watch your waistline',
  40: 'Naughty 40',
  41: "Life's begun at 41",
  42: 'The answer to everything',
  43: 'Pain in the knee',
  44: 'All the fours',
  45: 'Halfway there',
  46: 'Up to tricks',
  47: 'The Star Trek Number',
  48: 'Four dozen',
  49: 'Rise and shine',
  50: 'Amount of money cut for overacting',
  51: 'The alien number',
  52: 'Pack of Cards',
  53: 'Pack with a joker',
  54: 'The Perfect Round',
  55: 'All the fives',
  56: 'Ab tak 56',
  57: 'Mutiny Year',
  58: 'Time to retire',
  59: 'Just retired',
  60: 'Seconds in a minute',
  61: 'Bakers bun',
  62: 'Click the two',
  63: 'Click the three',
  64: 'Catch the chor',
  65: 'Old age pension',
  66: 'All the 6s',
  67: 'Made in heaven',
  68: 'Saving grace',
  69: 'Your place or mine',
  70: 'Lucky blind',
  71: 'Lucky bachelor',
  72: 'Lucky couple',
  73: 'A crutch and a flea',
  74: 'Lucky chore',
  75: 'Diamond Jubilee',
  76: 'Lucky six',
  77: 'Two hockey sticks',
  78: "Heaven's gate",
  79: 'lucky nine',
  80: 'Days to go around the world',
  81: 'Corner shot',
  82: 'Last of the two',
  83: 'India wins Cricket World Cup',
  84: 'Last of the chores',
  85: 'Last of the fives',
  86: 'Last six',
  87: 'The unlucky prime',
  88: 'Miles per hour to go Back to the Future',
  89: 'All but one',
  90: 'Top of the house',
}

export default dialogues
