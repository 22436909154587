import React, { useState, useEffect, useContext } from 'react'
import cx from 'classnames'
import loadable from '@loadable/component'
import { Button } from '../../../components/Button'
import { GameSwitchButton } from '../../../components/games/RandomBlocks'
import dialogues from '../../../components/games/dialogues'
import config from '../../../../data/SiteConfig'
import ThemeContext from '../../../context/ThemeContext'

const Layout = loadable(() => import('../../../layout'))

const RandomGeneratorComponent = loadable(() => import('../../../components/games/RandomGenerator'))

const Tambola = ({ location }) => {
  const [blockArray, setBlockStatus] = useState(new Array(90).fill(false))
  const [sequence, setSequence] = useState([])
  const [isGameOver, setGameOverStatus] = useState(false)
  const [shouldShowSequence, toggleShowSequence] = useState(false)
  const [nextNumber, setNextNumber] = useState('')
  const [lastNumber, setLastNumber] = useState('...')

  const nextRandomInteger = () => {
    let x = (Math.floor(Math.random() * 100) % 90) + 1

    if (sequence.length === 90) {
      setGameOverStatus(true)
      return -1
    }
    while (sequence.includes(x)) {
      x = (Math.floor(Math.random() * 100) % 90) + 1
    }
    setSequence([...sequence, x])

    return x
  }

  const checkSpeechCapability = () => {
    // Check for browser support
    if ('speechSynthesis' in window) {
      // Speech Synthesis supported 🎉
      // eslint-disable-next-line no-console
      console.log('Speech Synthesis supported 🎉')
    } else {
      // Speech Synthesis Not Supported 😣
      // eslint-disable-next-line no-console
      console.log(
        "Sorry, your browser doesn't support text to speech!, hence we'll not be able to speak numbers",
      )
    }
  }

  useEffect(checkSpeechCapability, [])

  const theme = useContext(ThemeContext)

  const speakNumber = (value) => {
    const msg = new SpeechSynthesisUtterance()
    msg.text = `${dialogues[value]}, number ${value}`
    window.speechSynthesis.speak(msg)
  }

  const setNumberStatus = (number) => {
    setNextNumber(number)
    if (theme.soundEnabled) {
      speakNumber(number)
    }

    setBlockStatus([
      ...blockArray.slice(0, number - 1),
      number,
      ...blockArray.slice(number, blockArray.length),
    ])
  }

  const generateNumber = () => {
    const randomNumber = nextRandomInteger()
    if (randomNumber === -1) {
      return
    }
    if (nextNumber === '') {
      setNumberStatus(randomNumber)
    } else {
      setLastNumber(nextNumber)
      setNumberStatus(randomNumber)
    }
    // eslint-disable-next-line consistent-return
    return randomNumber
  }

  const resetGame = () => {
    window.location.reload()
  }

  const seoDescription =
    'Host free Tambola / Housie / Indian Bingo online Play free Tambola ads | Ad free Tambola'
  return (
    <Layout
      title={`Host free Tambola / Housie Game without Ads – ${config.siteTitle}`}
      seoDescription={seoDescription}
      seoImage={config.tambolaCover}
      href={location.href}
    >
      <div className="container text-center">
        <h1 className="text-center">Tambola Game</h1>
        {isGameOver ? (
          <h2 className="text-center">Game Over</h2>
        ) : (
          <RandomGeneratorComponent
            nextRandomInteger={generateNumber}
            lastNumber={lastNumber}
            isGameOver={isGameOver}
          />
        )}
        <div className="grid numbers-blocks">
          {blockArray.map((el, index) => (
            <div key={index} className={cx({ activeColor: !!el, b: !!el }, 'white block noselect')}>
              {index + 1}
            </div>
          ))}
        </div>
        <div className="bottom-section flex">
          <div className="section-number-prev">
            Previous Number
            <h2>{lastNumber}</h2>
          </div>
          {!!sequence.length && (
            <div className="section-sequence">
              <Button onClick={() => toggleShowSequence(!shouldShowSequence)}>
                {shouldShowSequence ? 'Hide Sequence' : 'Show Sequence'}
              </Button>
              <h2 className="margin-0">{shouldShowSequence ? sequence.join(', ') : '...'}</h2>
            </div>
          )}
        </div>
      </div>
      <div className="flex-center">
        <Button className="red-background" onClick={resetGame}>
          Restart Game
        </Button>
      </div>
      <GameSwitchButton path="/games/tambola/generate-ticket" buttonTitle="Generate A Ticket" />
    </Layout>
  )
}

export default Tambola
