/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { ButtonLink } from '../Button'

const margin2rem = {
  margin: '2rem',
}

export default function GameSwitchButton({ path, buttonTitle, ...props }) {
  return (
    <section className="flex-center flex-direction-column h-100" style={margin2rem}>
      <span className="b">OR</span>
      <ButtonLink title={buttonTitle} style={margin2rem} href={path} {...props}>
        {buttonTitle}
      </ButtonLink>
    </section>
  )
}

export { GameSwitchButton }
